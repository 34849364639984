
import { mascaraMoney } from '@/directive/money';
import { useStore } from '@/store';
import { OBTER_REVISTA } from '@/store/medicamento/tipo-acoes-medicamento';
import { DEFINE_REVISTA } from '@/store/medicamento/tipo-mutacoes-medicamento';
import { SAIR_SISTEMA } from '@/store/tipo-acoes';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { onUnmounted } from 'vue';
import { defineComponent } from 'vue';
import DetalheMedicamentoRevista from '@/components/DetalheMedicamentoRevista.vue';
import { IPreco } from '@/interfaces/IDetalheMedicamento'
import { EFETUAR_DOWNLOAD_EXCEL } from '@/store/atualizacao/tipo-acoes-atualizacao';

export default defineComponent({
    name: 'revista-eletronica',
    components: { DetalheMedicamentoRevista },
    data() {
        return {
            paginaAtual: 0,
            totalPagina: 25,
            laboratorio: '',
            isLoading: false,
            tipoFiltro: 'Nome Produto',
            filtro: '',
            tableHeight: "80vh",
            medicamentoSelecionado: '0',
            dialog: false,
            precoAtual: [] as IPreco[]
        }
    },
    methods: {
        toggleFilter() {
            this.tableHeight = this.tableHeight === "49vh" ? "80vh" : "49vh";
        },

        logOut() {
            this.store.dispatch(SAIR_SISTEMA).then(() => this.$router.push("/login"));
        },
        filtrar() {
            this.isLoading = true;
            this.store.dispatch(OBTER_REVISTA,
                {
                    page: this.paginaAtual,
                    size: this.totalPagina,
                    laboratorio: this.laboratorio,
                    principioAtivo: this.tipoFiltro === 'Nome Produto' ? '' : this.filtro,
                    produto: this.tipoFiltro === 'Nome Produto' ? this.filtro : '',
                    codBarra: this.tipoFiltro === 'Código Barras' ? this.filtro : ''
                }
            );
            this.isLoading = false;
        },
        updateDataMedicamentos(evt: number) {
            this.paginaAtual = evt - 1;
            this.filtrar();
        },
        limpar() {
            this.paginaAtual = 0;
            this.totalPagina = 25;
            this.laboratorio = '';
            this.tipoFiltro = 'Nome Produto';
            this.filtro = '';
            this.filtrar();

        },
        abrirDetalhe(id: string, precos: IPreco[]) {
            this.medicamentoSelecionado = id;
            this.precoAtual = precos;
            this.dialog = true
        },
        downloadExcel() {
            this.isLoading = true;
            this.store.dispatch(EFETUAR_DOWNLOAD_EXCEL, '').then(() => {
                this.isLoading = false;
            });
        },
        downloadPDF() {
            console.log('Em breve')
        },
        mascaraMoney(value: number) {
            return mascaraMoney(value);
        }
    },
    setup() {
        const store = useStore();

        onUnmounted(() => store.commit(DEFINE_REVISTA, []));

        onMounted(() => store.dispatch(OBTER_REVISTA,
            {
                page: 0,
                size: 25,
                laboratorio: '',
                principioAtivo: '',
                produto: '',
                codBarra: ''
            }
        ));

        const medicamentos = computed(() => store.state.medicamento.medicamentos);

        return {
            store,
            medicamentos
        }
    }
});
