
import { IDadosParametro } from '@/interfaces/IDadosParametro';
import { TipoNotificacao } from '@/interfaces/INotificacao';
import { useStore } from '@/store';
import { CARREGA, NOTIFICA } from '@/store/tipo-mutacoes';
import { ATUALIZAR_PARAMETRO_USUARIO, PARAMETROS_USUARIO } from '@/store/usuario/tipo-acoes-usuario';
import { defineComponent } from 'vue';
import { VForm } from 'vuetify/components/VForm';

export default defineComponent({
    name: 'parametro-usuario',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            params: {} as IDadosParametro
        }
    },
    methods: {
        hasHistory() { return window.history.length > 2 },
        onSubmit() {
            (this.$refs.form as VForm).validate().then(() => {
                this.store.dispatch(ATUALIZAR_PARAMETRO_USUARIO, this.params.usuario.id).
                    then((response) => {
                        if (response.status == 200 || response.status == 201) {
                            this.store.commit(NOTIFICA, {
                                titulo: 'Parametro',
                                texto: 'Usuário Alterado',
                                tipo: TipoNotificacao.SUCESSO
                            });
                            this.hasHistory() ? this.$router.go(-1) : this.$router.push("/usuario/lista");
                        }
                    });
            });
        }
    },
    mounted() {
        if (this.id) {
            this.store.commit(CARREGA, true);

            this.store.dispatch(PARAMETROS_USUARIO, this.id).then(response => {
                const configuracao = response.data;
                if (configuracao) {
                    this.params = configuracao;
                    console.log(this.params);
                }
            });

            this.store.commit(CARREGA, false);
        }
    },
    setup() {
        const store = useStore();

        return {
            store
        }
    }

});
