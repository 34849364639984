import { trataErro } from "./http";
import axios, { AxiosError } from "axios";
import { useStore } from "@/store";

export interface CepObject {

    cep: string,
    logradouro: string,
    complemento: string,
    bairro: string,
    localidade: string,
    uf: string,
    ibge: number,
    gia: number,
    ddd: number,
    siafi: number,

}

export function consultarCep(cep: string): Promise<any> {

    return axios.create({
        baseURL: 'https://viacep.com.br',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        withCredentials: false
    }).get('/ws/' + cep + '/json/').catch((erro: AxiosError) => {
        trataErro(useStore().commit, 'Consulta CEP', erro);
    });

}

