// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { pt } from 'vuetify/locale'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
    locale: {
        locale: 'pt',
        fallback: 'en',
        messages: { pt }
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                dark: false,
                colors: {
                    background: '#e3e1ec',
                    surface: '#fefbff',
                    primary: '#4355b9',
                    'primary-darken-1': '#00105c',
                    secondary: '#5b5d72',
                    'secondary-darken-1': '#181a2c',
                    error: '#ba1a1a',
                    info: '#8C9EFF',
                    success: '#4CAF50',
                    warning: '#FB8C00',
                }
            }
        }
    },

})
