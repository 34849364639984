export const OBTER_USUARIOS = 'OBTER_USUARIOS'
export const OBTER_USUARIOS_INATIVOS = 'OBTER_USUARIOS_INATIVOS'
export const OBTER_USUARIO = 'OBTER_USUARIO'
export const OBTER_USUARIO_IDSISTEMA = 'OBTER_USUARIO_IDSISTEMA'
export const ADICIONAR_USUARIO = 'ADICIONAR_USUARIO'
export const PARAMETROS_USUARIO = 'PARAMETROS_USUARIO'
export const ATUALIZAR_PARAMETRO_USUARIO = 'ATUALIZAR_PARAMETRO_USUARIO'
export const ADICIONAR_COBRANCA = 'ADICIONAR_COBRANCA'
export const OBTER_COBRANCAS = 'OBTER_COBRANCAS'
export const MUDAR_ESTADO_COBRANCA = 'MUDAR_ESTADO_COBRANCA'
export const REMOVER_COBRANCA = 'REMOVER_COBRANCA'
export const DOWNLOAD_COBRANCA = 'DOWNLOAD_COBRANCA'


export const VERIFICAR_LOGIN = 'VERIFICAR_LOGIN'
export const VERIFICAR_IDSISTEMA = 'VERIFICAR_IDSISTEMA'
export const VERIFICAR_CNPJ = 'VERIFICAR_CNPJ'