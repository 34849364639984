
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import { SAIR_SISTEMA } from '@/store/tipo-acoes';

export default defineComponent({
    name: "Barra-Lateral",
    data() {
        return {
            drawer: true,
            rail: false,
            avatar: require('../assets/user.png')

        }
    },
    methods: {
        logOut() {
            this.store.dispatch(SAIR_SISTEMA).then(() => this.router.push("/login"));
        }
    },
    setup() {
        const router = useRouter()
        const store = useStore();

        return {
            router,
            store,
            usuarioLogado: computed(() => store.state.usuarioAuth)
        }
    }
})
