
import { useStore } from '@/store';
import { computed } from 'vue';
import { defineComponent } from 'vue';


export default defineComponent({
    name: 'loader-component',
    setup(){
        const store = useStore();

        return {
            isLoading: computed(() => store.state.loading),
        };

    }
});
