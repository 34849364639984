
import { createStore, Store, useStore as vuexUseStore } from 'vuex'
import { InjectionKey } from "vue";
import { INotificacao, TipoNotificacao } from "@/interfaces/INotificacao";
import { IDetalheUsuario } from "@/interfaces/IDetalheUsuario";
import { ILogin } from "@/interfaces/ILogin";
import { IDadosTrocaSenha } from "@/interfaces/IDadosTrocaSenha";
import { CARREGA, LOGA, NOTIFICA } from "./tipo-mutacoes";
import { LOGAR_API, RENOVAR_TOKEN, TROCAR_SENHA, SAIR_SISTEMA, CARREGAR_CONFIGURACAO, ATUALIZAR_CONFIGURACAO, MUDAR_DATA_BLOQUEIO, RECUPERAR_DATA_BLOQUEIO, MUDAR_MODULO, RECUPERAR_MODULO, TESTAR_EMAIL, RESETAR_SENHA } from './tipo-acoes';
import { httpClient, http, trataErro } from '@/service/http';
import { getUser } from '@/service/auth';
import { USUARIO_LOGADO } from './usuario/tipo-mutacoes-usuario';
import { EstadoUsuario, usuario } from './usuario';
import { EstadoModulo, modulo } from './modulo';
import { EstadoAtualizacao, atualizacao } from './atualizacao';
import { MedicamentoEstado, MedicamentoModulo } from './medicamento';
import { IDetalheAtualizacao } from '@/interfaces/IDetalheAtualizacao';
import { IDetalheRevista } from '@/interfaces/IRevistaMedicamento';
import { IDetalheMedicamento } from '@/interfaces/IDetalheMedicamento';
import { IConfiguracaoSAF } from '@/interfaces/IConfiguracaoSAF';
import { IPage } from '@/interfaces/IPage';
import { IDetalheModulo } from '@/interfaces/IDetalheModulo';

export interface Estado {
    notificacoes: INotificacao[];
    configuracaoSAF: IConfiguracaoSAF,
    loading: boolean;
    loginSalvo: ILogin;
    usuarioAuth: IDetalheUsuario | undefined;
    token: string | undefined;
    usuario: EstadoUsuario;
    modulo: EstadoModulo;
    atualizacao: EstadoAtualizacao;
    medicamento: MedicamentoEstado;
}

export const key: InjectionKey<Store<Estado>> = Symbol();

export const store = createStore<Estado>({
    modules: {
        usuario,
        modulo,
        atualizacao,
        medicamento: MedicamentoModulo,
    },
    state: {
        notificacoes: [],
        configuracaoSAF: {} as IConfiguracaoSAF,
        loading: false,
        loginSalvo: {} as ILogin,
        usuarioAuth: undefined,
        token: undefined,
        usuario: {
            usuarios: [],
            cobrancas: []
        },
        modulo: {
            modulos: {} as IPage<IDetalheModulo>
        },
        atualizacao: {
            atualizacoes: {} as IPage<IDetalheAtualizacao>,
            dadosAtualizacaoAtual: {} as IDetalheAtualizacao,
            dadosErroAtualizacaoAtual: undefined,
        },
        medicamento: {
            medicamentos: {} as IDetalheRevista,
            medicamento: {} as IDetalheMedicamento
        }
    },
    mutations: {
        [NOTIFICA](state, novaNotificacao: INotificacao) {
            novaNotificacao.id = new Date().getTime()
            state.notificacoes.push(novaNotificacao)

            setTimeout(() => {
                state.notificacoes = state.notificacoes.filter(notificacao => notificacao.id != novaNotificacao.id)
            }, 3000)
        },
        [CARREGA](state, value: boolean) {
            state.loading = value;
        },
        [LOGA](state, loging: ILogin) {
            state.loginSalvo = loging;
        },
        [USUARIO_LOGADO](state, token: string) {

            state.usuarioAuth = getUser(token);

            state.token = token
        }
    },
    actions: {
        [LOGAR_API](context, login: ILogin) {
            context.commit(CARREGA, true);
            return httpClient.post('/login', login).then((response) => {
                context.commit(LOGA, login);
                context.commit(NOTIFICA, {
                    titulo: 'Login',
                    texto: 'Login correto',
                    tipo: TipoNotificacao.SUCESSO,
                });
                context.commit(USUARIO_LOGADO, response.data.token);
            }).catch(error => {
                trataErro(context, 'Login', error);

            }).finally(() => context.commit(CARREGA, false));

        },
        [RENOVAR_TOKEN](context) {
            return httpClient.post('/login', context.state.loginSalvo).then((resp) => {
                context.commit(USUARIO_LOGADO, resp.data.token);
                context.commit(LOGA, context.state.loginSalvo);
                context.commit(NOTIFICA, {
                    titulo: 'Login',
                    texto: 'Login Renovado',
                    tipo: TipoNotificacao.SUCESSO,
                });
            }).catch(error => {
                context.commit(USUARIO_LOGADO, undefined);
                trataErro(context, 'Renovar Login', error);
            });
        },
        [TROCAR_SENHA](context, senha: IDadosTrocaSenha) {
            return http.put('/usuario/trocarSenha', senha).then(() => {
                context.commit(NOTIFICA, {
                    titulo: 'Trocar Senha',
                    texto: 'Senha Trocada',
                    tipo: TipoNotificacao.SUCESSO,
                });
                context.commit(USUARIO_LOGADO, undefined);
            }).catch(error => {
                trataErro(context, 'Trocar Senha', error);
            });
        },
        [RESETAR_SENHA](context, emailRecuperacao: string) {
            return http.put('/login/resetar-senha', '', {
                params: {
                    email: emailRecuperacao
                }
            }).then(() => {
                context.commit(NOTIFICA, {
                    titulo: 'Esqueçeu Senha',
                    texto: 'A Senha foi enviada para o email',
                    tipo: TipoNotificacao.SUCESSO,
                });
                context.commit(USUARIO_LOGADO, undefined);
            }).catch(error => {
                trataErro(context, 'Esqueçeu senha', error);
            });
        },
        [SAIR_SISTEMA]({ commit }) {
            commit(USUARIO_LOGADO, undefined);
            commit(NOTIFICA, {
                titulo: 'Logout',
                texto: 'Usuário foi desconectado',
                tipo: TipoNotificacao.INFORMACAO,
            });
        },
        [CARREGAR_CONFIGURACAO](context) {
            return http.get('/configuracao').catch(error => {
                console.log(error);
                trataErro(context, 'Erro ao carregar Configuração', error);
            });
        },
        [ATUALIZAR_CONFIGURACAO](context, config: IConfiguracaoSAF) {
            return http.post('/configuracao', config).catch(error => {
                trataErro(context, 'Erro ao atualizar Configuração', error);
            });
        },
        [TESTAR_EMAIL](context, { emailUserName, emailHost, emailPorta, emailPassword, emailSmtpAuth, emailTLSEnabled, emailTLSRequired, emailTest }) {
            return http.put('/configuracao/teste-email', {
                emailUserName, emailHost, emailPorta,
                emailPassword, emailSmtpAuth, emailTLSEnabled, emailTLSRequired, emailTest
            }).catch(error => {
                trataErro(context, 'Erro ao Enviar Email Teste', error);
            });
        },
        [MUDAR_DATA_BLOQUEIO](context, { nrSerie, dataBloqueio }) {
            const matches = /(\d{4})[-.\\/](\d{2})[-.\\/](\d{2})/.exec(dataBloqueio);
            return http.put('/cliente-localweb/mudar-data-bloqueio', null, {
                params: {
                    nrSerie: nrSerie,
                    dataBloqueio: matches ? dataBloqueio : null
                }
            }).catch((erro) => trataErro(context, 'Bloqueio', erro));
        },
        [RECUPERAR_DATA_BLOQUEIO](context, nrSerie) {
            return http.get('/cliente-localweb/data-bloqueio', {
                params: {
                    nrSerie: nrSerie,
                }
            }).catch((erro) => trataErro(context, 'Bloqueio', erro));
        },
        [MUDAR_MODULO](context, { nrSerie, modulo_id }) {
            return http.put('/cliente-localweb/mudar-mudulo', null, {
                params: {
                    nrSerie: nrSerie,
                    modulo: modulo_id
                }
            }).catch((erro) => trataErro(context, 'Mudar Môdulo', erro));
        },
        [RECUPERAR_MODULO](context, nrSerie) {
            return http.get('/cliente-localweb/mudulo', {
                params: {
                    nrSerie: nrSerie,
                }
            }).catch((erro) => trataErro(context, 'Modulo', erro));
        },
    }
});

export function useStore(): Store<Estado> {
    return vuexUseStore(key)
}