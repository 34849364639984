
import { defineComponent, computed, onUnmounted, onMounted } from 'vue';
import { useStore } from "@/store";
import { OBTER_USUARIOS, OBTER_USUARIOS_INATIVOS } from "@/store/usuario/tipo-acoes-usuario";
import { DEFINE_USUARIOS } from '@/store/usuario/tipo-mutacoes-usuario';
import { mascara } from '@/directive/mask';


export default defineComponent({
    name: 'lista-usuario',
    data() {
        return {
            filtro: '',
            somenteInativo: false
        }
    },
    methods: {
        filtrar() {
            console.log(this.somenteInativo);
            (this.somenteInativo) ?
                this.store.dispatch(OBTER_USUARIOS_INATIVOS, this.filtro) :
                this.store.dispatch(OBTER_USUARIOS, this.filtro);

        },
        alterar(id: string) {
            this.$router.push("cadastro/" + id);
        },
        configurarParametro(id: string) {
            this.$router.push("parametro/" + id);
        },
        novo() {
            this.$router.push("novo");
        },
        mascTel(value: string): string {
            return mascara('TEL', value)
        }

    },
    setup() {
        const store = useStore();
        onUnmounted(() => store.commit(DEFINE_USUARIOS, []));
        onMounted(() => store.dispatch(OBTER_USUARIOS));
        const usuarios = computed(() => store.state.usuario.usuarios);
        return {
            store,
            usuarios

        }
    }
})
