export const LOGAR_API = 'LOGAR_API'
export const RENOVAR_TOKEN = 'RENOVAR_TOKEN'

export const SAIR_SISTEMA = 'SAIR_SISTEMA'
export const TROCAR_SENHA = 'TROCAR_SENHA'
export const RESETAR_SENHA = 'RESETAR_SENHA'
export const NOTIFICAR = 'NOTIFICAR'

export const CARREGAR_CONFIGURACAO = 'CARREGAR_CONFIGURACAO'
export const ATUALIZAR_CONFIGURACAO = 'ATUALIZAR_CONFIGURACAO'
export const TESTAR_EMAIL = 'TESTAR_EMAIL'

export const MUDAR_DATA_BLOQUEIO = 'MUDAR_DATA_BLOQUEIO'
export const MUDAR_MODULO = 'MUDAR_MODULO'
export const RECUPERAR_DATA_BLOQUEIO = 'RECUPERAR_DATA_BLOQUEIO'
export const RECUPERAR_MODULO = 'RECUPERAR_MODULO'
