import { Module } from "vuex";
import { Estado } from "@/store";
import { http, trataErro } from "@/service/http";
import { ADICIONAR_USUARIO, OBTER_USUARIOS,OBTER_USUARIOS_INATIVOS, OBTER_USUARIO, VERIFICAR_LOGIN, VERIFICAR_IDSISTEMA, VERIFICAR_CNPJ, PARAMETROS_USUARIO, ATUALIZAR_PARAMETRO_USUARIO, ADICIONAR_COBRANCA, MUDAR_ESTADO_COBRANCA, REMOVER_COBRANCA, OBTER_USUARIO_IDSISTEMA, OBTER_COBRANCAS, DOWNLOAD_COBRANCA } from "@/store/usuario/tipo-acoes-usuario";
import { DEFINE_COBRANCAS, DEFINE_USUARIOS } from "@/store/usuario/tipo-mutacoes-usuario";
import { NOTIFICA } from "@/store/tipo-mutacoes";
import { IDetalheUsuario } from "@/interfaces/IDetalheUsuario";
import { IDadosUsuario } from "@/interfaces/IDadosUsuario"
import { TipoNotificacao } from "@/interfaces/INotificacao";
import { IDetalheCobranca } from "@/interfaces/IDetalheCobranca";

export interface EstadoUsuario {
    usuarios: IDetalheUsuario[],
    cobrancas: IDetalheCobranca[]
}

export const usuario: Module<EstadoUsuario, Estado> = {
    mutations: {
        [DEFINE_USUARIOS](state, usuarios: IDetalheUsuario[]) {
            state.usuarios = usuarios;
        },
        [DEFINE_COBRANCAS](state, cobrancas: IDetalheCobranca[]) {
            state.cobrancas = cobrancas;
        }
    },
    actions: {
        [OBTER_USUARIOS](context, filtro) {
            http.get('/usuario/listar', {
                params: {
                    filtro: filtro || ''
                }
            })
                .then(response =>
                    context.commit(DEFINE_USUARIOS, response.data.content))
                .catch(error => {
                    context.commit(DEFINE_USUARIOS, []);
                    trataErro(context, 'Usuário', error);
                });
        },
        [OBTER_USUARIOS_INATIVOS](context, filtro) {
            http.get('/usuario/listar-inativos', {
                params: {
                    filtro: filtro || ''
                }
            })
                .then(response =>
                    context.commit(DEFINE_USUARIOS, response.data.content))
                .catch(error => {
                    context.commit(DEFINE_USUARIOS, []);
                    trataErro(context, 'Usuário', error);
                });
        },
        [OBTER_USUARIO](context, id: string) {
            return http.get('/usuario', {
                params: {
                    id: id
                }
            }).catch(error => {
                trataErro(context, 'Usuário', error);
            });
        },
        [OBTER_USUARIO_IDSISTEMA](context, id: number) {
            return http.get('/usuario/recuperar-idsistema', {
                params: {
                    id: id
                }
            }).catch(error => {
                trataErro(context, 'Usuário', error);
            });
        },
        [ADICIONAR_USUARIO](context, form: IDadosUsuario) {
            return (form.id && form.id.length > 0) ?
                http.put('/usuario', JSON.stringify(form),
                    {
                        params: {
                            id: form.id
                        }
                    }
                ).catch(error => {
                    context.commit(DEFINE_USUARIOS, []);
                    trataErro(context, 'Usuário', error);
                })
                :
                http.post('/usuario', JSON.stringify(form))
                    .then(response => {
                        if (response.status == 201) {
                            context.commit(NOTIFICA, {
                                titulo: 'Usuário',
                                texto: 'Usuário Cadastrado',
                                tipo: TipoNotificacao.SUCESSO,
                            });
                        }
                    })
                    .catch(error => {
                        context.commit(DEFINE_USUARIOS, []);
                        trataErro(context, 'Usuário', error);
                    })

        },
        [VERIFICAR_LOGIN](context, { id, login }) {
            return http.get('/usuario/loginDisponivel', {
                params: {
                    id: id,
                    login: login
                }
            }).then(response => {
                return response.data.disponivel || 'Login não está disponível';
            }).catch(error => {
                trataErro(context, 'Usuário', error);
            });
        },
        [VERIFICAR_IDSISTEMA](context, { id, idSistema }) {

            return http.get('/usuario/idSistemaDisponivel', {
                params: {
                    id: id,
                    idSistema: idSistema
                }
            }).then(resp => {
                return resp.data.disponivel || 'Código do Sistema já está cadastrado';
            }).catch(error => {
                trataErro(context, 'Usuário', error);
            })
        },
        [VERIFICAR_CNPJ](context, { id, cnpj }) {
            return http.get('/usuario/cpnjCadastrado', {
                params: {
                    id: id,
                    cnpj: cnpj
                }
            }).then(response => {
                return response.data.disponivel || 'CNPJ/CPF já está cadastrado ';
            }).catch(error => {
                trataErro(context, 'Usuário', error);
            });
        },
        [PARAMETROS_USUARIO](context, id) {
            return http.get('/parametro-usuario/recuperar-parametro', {
                params: {
                    id: id
                }
            }).catch(error => {
                trataErro(context, 'Usuário', error);
            });
        },
        [ATUALIZAR_PARAMETRO_USUARIO](context, { idUsuario, lista }) {
            http.put('/parametro-usuario', JSON.stringify(lista),
                {
                    params: {
                        id: idUsuario
                    }
                }
            ).catch(error => {
                context.commit(DEFINE_USUARIOS, []);
                trataErro(context, 'Usuário', error);
            })
        },
        [ADICIONAR_COBRANCA](context, { idUsuario, dataVencimento, file }) {

            return http.post('/cobranca/upload-cobranca', { idUsuario: idUsuario, dataVencimento: dataVencimento, file: file }
                , {
                    headers: {
                        'Content-type': 'multipart/form-data'
                    }
                }).catch((erro) => {
                    trataErro(context, 'Cobrança', erro)
                });
        },
        [MUDAR_ESTADO_COBRANCA](context, { id, vencimento, dataLiquidacao }) {
            http.put('/cobranca/mudar-estado-cobranca', null,
                {
                    params: {
                        id: id,
                        vencimento: vencimento,
                        dataLiquidacao: dataLiquidacao
                    }
                }
            ).catch(error => {
                trataErro(context, 'Cobrança', error);
            })
        },
        [OBTER_COBRANCAS](context, id) {
            return http.get('/cobranca', {
                params: {
                    id: id
                }
            })
                .then((response) => {
                    context.commit(DEFINE_COBRANCAS, response.data)
                })
                .catch(error => {
                    trataErro(context, 'Cobrança', error);
                });
        },
        [DOWNLOAD_COBRANCA](context, { id, vencimento }) {
            return http.get('/cobranca/download', {
                params: {
                    id: id, dataVencimento: vencimento,
                }
            })
                .catch(error => {
                    trataErro(context, 'Cobrança', error);
                });
        },
        [REMOVER_COBRANCA](context, { id, vencimento }) {
            http.delete('/cobranca/remover-cobranca',
                {
                    params: {
                        id: id,
                        dataVencimento: vencimento,
                    }
                }
            ).catch(error => {
                trataErro(context, 'Cobrança', error);
            })
        },
    },
    getters: {
        usuarios(state) {
            return state.usuarios
        }
    }
}