
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import { LOGAR_API, RESETAR_SENHA } from "@/store/tipo-acoes";
import { useRouter } from "vue-router";
import validator from "@/util/validator";

export default defineComponent({
    name: 'Login-component',
    data: () => ({
        form: false,
        usuario: null,
        senha: null,
        loading: false,
        visible: false,
        dialog: false,
        emailRecuperar: '',
        required: validator.methods.required
    }),
    methods: {
        onSubmit() {
            if (!this.form) return

            this.loading = true;
            this.store.dispatch(LOGAR_API, { "login": this.usuario, "senha": this.senha })
                .then(() => {
                    if (!this.usuarioLogado) return;
                    console.log(this.usuarioLogado.trocarSenhaProximoLogin);
                    if (this.usuarioLogado.trocarSenhaProximoLogin)
                        this.router.push("/trocar-senha")
                    else {
                        this.usuarioLogado.funcionario === true ?
                            this.router.push("/") :
                            this.router.push("/revista");
                    }


                });
            this.loading = false;
        },
        recuperarSenha() {
            this.store.dispatch(RESETAR_SENHA, this.emailRecuperar)
                .then(() => {
                    this.dialog = false;
                });


        }

    },
    setup() {
        const store = useStore();
        const router = useRouter();

        return {
            store,
            router,
            usuarioLogado: computed(() => store.state.usuarioAuth)
        }
    }
});
