
import { defineComponent, computed } from 'vue';
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import validator from '@/util/validator';
import { TROCAR_SENHA } from '@/store/tipo-acoes'

export default defineComponent({
    name: "trocar-senha",
    data() {
        return {
            form: false,
            senhaAntiga: null,
            senha: null,
            confirmar: null,
            loading: false,
            required: validator.methods.required
        }
    },
    methods: {
        onSubmit() {

            if (!this.form) return

            if (!this.usuarioLogado) return

            this.loading = true;

            this.store.dispatch(TROCAR_SENHA, { "login": this.usuarioLogado.login, "senhaAntiga": this.senhaAntiga, "senhaNova": this.senha })
                .then(() => this.router.push("/"));

            this.loading = false;
        },
        senhaIgual(v: any) {
            return (v == this.senha) || 'Confirmação de senha não confere'
        }
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        return {
            store,
            router,
            usuarioLogado: computed(() => store.state.usuarioAuth)
        }
    }
})
