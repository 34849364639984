import { IDetalheAtualizacao } from "@/interfaces/IDetalheAtualizacao"
import { http, trataErro } from "@/service/http"
import { Estado } from "@/store"
import { Module } from "vuex"
import { INICAR_ATUALIZACAO, OBTER_ATUALIZACOES, EFETUAR_DOWNLOAD_EXCEL, FILTRAR_ATUALIZACAO, EFETUAR_DOWNLOAD_RECEITA } from "./tipo-acoes-atualizacao"
import { DEFINE_ATUALIZACOES, DEFINE_DADOS_ATUALIZACAO, DEFINE_ERROS_ATUALIZACAO } from "./tipo-mutacoes-atualizacao"
import { IDetalheErroCabecalho } from "@/interfaces/IDetalheErroAtualizacao"
import { IPage } from "@/interfaces/IPage"

export interface EstadoAtualizacao {
    atualizacoes: IPage<IDetalheAtualizacao>,
    dadosAtualizacaoAtual: IDetalheAtualizacao,
    dadosErroAtualizacaoAtual: IDetalheErroCabecalho | undefined,
}

export const atualizacao: Module<EstadoAtualizacao, Estado> = {
    mutations: {
        [DEFINE_ATUALIZACOES](state, atualizacoes: IPage<IDetalheAtualizacao>) {
            state.atualizacoes = atualizacoes
        },
        [DEFINE_ERROS_ATUALIZACAO](state, value: IDetalheErroCabecalho) {
            state.dadosErroAtualizacaoAtual = value
        },
        [DEFINE_DADOS_ATUALIZACAO](state, value: IDetalheAtualizacao) {
            state.dadosAtualizacaoAtual = value
        }
    },
    actions: {
        [OBTER_ATUALIZACOES](context, { idUsuario, dataInicio, dataFinal, sucesso }) {
            return http.get('/atualizacaoMedicamento/filtro', {
                params: {
                    idUsuario: (idUsuario) ? idUsuario : null,
                    dataInicio: (dataInicio) ? dataInicio : null,
                    dataFinal: (dataFinal) ? dataFinal : null,
                    sucesso: (sucesso) ? sucesso : null
                }
            }).catch((erro) => trataErro(context, 'Atualização', erro));
        },
        [INICAR_ATUALIZACAO](context, { dataVigencia, descricao, file }) {
            return http.post('/atualizacaoMedicamento/atualizar', {
                dataVigencia: dataVigencia,
                descricao: descricao,
                file: file,
            }, {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            }).then((resp) => {
                context.commit(DEFINE_ERROS_ATUALIZACAO, {})
                context.commit(DEFINE_DADOS_ATUALIZACAO, resp.data)
            }).catch((erro) => {             
                context.commit(DEFINE_ERROS_ATUALIZACAO, erro.response.data)
                context.commit(DEFINE_DADOS_ATUALIZACAO, {})
                trataErro(context, 'Atualização', erro)
            });
        },
        [EFETUAR_DOWNLOAD_EXCEL](context, idAtualizacao: string) {
            return http.get('/download/excel', {
                params: {
                    idAtualizacao: idAtualizacao
                },
                responseType: 'blob'
            }).then((response: any) => {
                const filename = response.headers['content-disposition'].split('=')[1].replace(/'/g, '').trim();
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
            }).catch((erro) => trataErro(context, 'Donwload Excel', erro))
        },
        [EFETUAR_DOWNLOAD_RECEITA](context, idAtualizacao: string) {
            return http.get('/download/receita', {
                params: {
                    idAtualizacao: idAtualizacao
                },
                responseType: 'blob'
            }).then((response: any) => {
                const filename = response.headers['content-disposition'].split('=')[1].replace(/'/g, '').trim();
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
            }).catch((erro) => trataErro(context, 'Donwload Receita', erro))
        },
        [FILTRAR_ATUALIZACAO](context, { dataInicio, dataFinal }) {
            return http.get('/atualizacaoMedicamento/filtro', {
                params: {
                    dataInicio: dataInicio,
                    dataFinal: dataFinal
                }
            }).then((response: any) => {
                context.commit(DEFINE_ATUALIZACOES, response.data)
            }).catch((erro) => trataErro(context, 'Atualização', erro));
        }
    },
    getters: {
        atualizacoes(state) {
            return state.atualizacoes
        }
    }
} 