import axios, { AxiosInstance } from "axios";
import { NOTIFICA } from "@/store/tipo-mutacoes";
import { TipoNotificacao } from "@/interfaces/INotificacao";
import { store } from '@/store';

export const BASEURL_GLOBAL = 'https://api.revistainditec.com.br';
//export const BASEURL_GLOBAL = 'http://localhost:8080';


export const httpClient: AxiosInstance = axios.create({
    baseURL: BASEURL_GLOBAL,
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json'
    }
});


export const http: AxiosInstance = axios.create({
    baseURL: BASEURL_GLOBAL,
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
    }
});

http.interceptors.request.use(
    function (config) {
        const token = store.state.token;
        if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config
    }, function (erro) {
        return Promise.reject(erro)
    }
);

export const trataErro = (context: any, titulo: string, erro: any) => {
    if (!erro.response) {
        context.commit(NOTIFICA, {
            titulo: titulo,
            texto: 'erro ao coneca com servidor ',
            detalhes: erro.message,
            tipo: TipoNotificacao.FALHA,
        });
        return;
    }
    switch (erro.response.status) {
        case 400: {
            context.commit(NOTIFICA, {
                titulo: titulo,
                texto: 'Verifique os dados e repita a operação, caso persista entre em contato com o suporte',
                detalhes: erro.message,
                tipo: TipoNotificacao.FALHA,
            });
            break;
        }
        case 401: {
            context.commit(NOTIFICA, {
                titulo: titulo,
                texto: 'Sem autorização para ver esse conteúdo',
                detalhes: erro.message,
                tipo: TipoNotificacao.FALHA,
            });
            break;
        }
        case 403: {

            context.commit(NOTIFICA, {
                titulo: titulo,
                texto: titulo === 'Login' ? 'Usuário ou Senha estão errados, verifique' : ' ',
                detalhes: erro.message,
                tipo: TipoNotificacao.FALHA,
            });
            break;
        }
        case 408: {
            context.commit(NOTIFICA, {
                titulo: titulo,
                texto: 'Servidor congestionado ou fora do ar, verifique com o suporte',
                detalhes: erro.message,
                tipo: TipoNotificacao.FALHA,
            });
            break;
        }
        case 500: {
            context.commit(NOTIFICA, {
                titulo: titulo,
                texto: 'Erro no servidor, verifique com suporte',
                detalhes: erro.message,
                tipo: TipoNotificacao.FALHA,
            });
            break;
        }
        default: {
            context.commit(NOTIFICA, {
                titulo: titulo,
                texto: 'Entre em contato com o suporte',
                detalhes: erro.message,
                tipo: TipoNotificacao.FALHA,
            });
        }

    }
}
