import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    modelValue: _ctx.drawer,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.drawer) = $event)),
    rail: _ctx.rail,
    permanent: "",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.rail = false)),
    class: "bg-primary pt-2"
  }, {
    append: _withCtx(() => [
      _createVNode(_component_v_list, {
        density: "compact",
        nav: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            "prepend-icon": "mdi-form-textbox-password",
            title: "Troca Senha",
            value: "trocarSenha",
            to: "/trocar-senha"
          }),
          _createVNode(_component_v_list_item, {
            "prepend-icon": "mdi-logout",
            title: "Sair",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logOut())),
            value: "sair"
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list_item, {
        "prepend-avatar": _ctx.usuarioLogado?.foto || _ctx.avatar,
        title: _ctx.usuarioLogado?.nome,
        nav: ""
      }, {
        append: _withCtx(() => [
          _createVNode(_component_v_btn, {
            variant: "text",
            icon: "mdi-chevron-left",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.rail = !_ctx.rail), ["stop"]))
          })
        ]),
        _: 1
      }, 8, ["prepend-avatar", "title"]),
      _createVNode(_component_v_divider),
      _createVNode(_component_v_list, {
        density: "compact",
        nav: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            "prepend-icon": "mdi-account-group-outline",
            title: "Usuarios/Clientes",
            value: "usuario",
            to: "/usuario/lista"
          }),
          _createVNode(_component_v_list_item, {
            "prepend-icon": "mdi-book",
            title: "Módulos",
            value: "modulo",
            to: "/modulo/lista"
          }),
          _createVNode(_component_v_list_item, {
            "prepend-icon": "mdi-cog",
            title: "Configuração SAF",
            value: "configuracao",
            to: "/configuracao"
          }),
          _createVNode(_component_v_list_item, {
            "prepend-icon": "mdi-wrench",
            title: "Integração LocaWeb",
            value: "Integracao",
            to: "/integracao-locaweb"
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_list_item, {
            "prepend-icon": "mdi-wallet",
            title: "Cobrança",
            value: "Cobranca",
            to: "/cobrancas"
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_list_item, {
            "prepend-icon": "mdi-pill",
            title: "Medicamentos",
            value: "medicamento",
            to: "/revista"
          }),
          _createVNode(_component_v_list_item, {
            "prepend-icon": "mdi-update",
            title: "Atualização de Preço",
            value: "atualizacao",
            to: "/atualizacao"
          }),
          _createVNode(_component_v_list_item, {
            "prepend-icon": "mdi-account",
            title: "Preço no Cliente",
            value: "precocliente"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "rail"]))
}