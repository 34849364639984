
import { defineComponent, computed, onUnmounted, onMounted } from 'vue';
import { useStore } from "@/store";
import { OBTER_MODULOS } from '@/store/modulo/tipo-acoes-modulo';
import { DEFINE_MODULOS } from '@/store/tipo-mutacoes';
import { mascaraMoney } from '@/directive/money';
import { IPage } from '@/interfaces/IPage';
import { IDetalheModulo } from '@/interfaces/IDetalheModulo';

export default defineComponent({
    name: 'lista-modulo',
    data() {
        return {
            paginaAtual: 0,
            totalPagina: 25
        }
    },
    methods: {
        filtrar() {
            this.store.dispatch(OBTER_MODULOS, { page: this.paginaAtual, size: this.totalPagina });
        },
        alterar(id: number) {
            this.$router.push("cadastro/" + id);
        },
        novo() {
            this.$router.push("novo");
        },
        mascaraMoney(value: number) {
            return mascaraMoney(value, { prefix: 'R$' });
        }

    },
    setup() {
        const store = useStore();
        onUnmounted(() => store.commit(DEFINE_MODULOS, []));
        onMounted(() => store.dispatch(OBTER_MODULOS, { page: 0, size: 25 }));

        const modulos = computed(() => (store.state.modulo.modulos) ? store.state.modulo.modulos : {} as IPage<IDetalheModulo>);

        return {
            store,
            modulos

        }
    }
})
