
import { TipoNotificacao } from '@/interfaces/INotificacao';
import { defineComponent } from 'vue';
import useNotificador from "@/hooks/notificador";
import BarraLateral from '@/components/BarraLateral.vue';

export default defineComponent({
    name: "Home-component",
    components: {
        BarraLateral
    },
    methods: {
        novaNotifcacao(): void {
            this.notificador.notificar(TipoNotificacao.SUCESSO, "Teste", "teste de mensagem");
        }
    },
    setup() {
        const notificador = useNotificador();
        return {
            notificador
        }

    }
})
