
import { IConfiguracaoSAF } from '@/interfaces/IConfiguracaoSAF';
import { TipoNotificacao } from '@/interfaces/INotificacao';
import { useStore } from '@/store';
import { CARREGAR_CONFIGURACAO, ATUALIZAR_CONFIGURACAO,TESTAR_EMAIL } from '@/store/tipo-acoes';
import { CARREGA, NOTIFICA } from '@/store/tipo-mutacoes';
import validator from '@/util/validator';
import { defineComponent, ref } from 'vue';
import { VForm } from 'vuetify/components/VForm';

export default defineComponent({
    name: 'configuracao-component',
    props: ['id'],
    data() {
        return {
            configuracao: {
                urlApi: 'https://api.revistainditec.com.br/',
                usuarioAtualSistema: 'INDITEC',
                senhaAtualSistema: '1234'
            } as unknown as IConfiguracaoSAF,
            emailTest : 'desenvolvimento@inditec.com.br',
            requerid: validator.methods.required
        }
    },
    mounted() {
        this.store.commit(CARREGA, true);
        this.store.dispatch(CARREGAR_CONFIGURACAO).then(response => {
            const configuracaoGet = response ? response.data : undefined;
            if (configuracaoGet) {
                this.configuracao = configuracaoGet;
            } else
                (this.$refs.api as any).focus();
        }).finally(() => this.store.commit(CARREGA, false))


    },
    methods: {
        hasHistory() { return window.history.length > 2 },
        onSubmit() {
            (this.$refs.form as VForm).validate().then((obj) => {
                obj.valid ?
                    this.store.dispatch(ATUALIZAR_CONFIGURACAO, this.configuracao)
                        .then((response) => {
                            console.log(response);
                            if (response.status == 200 || response.status == 201) {
                                this.store.commit(NOTIFICA, {
                                    titulo: 'Cadastro',
                                    texto: 'Configuracao Atualizada',
                                    tipo: TipoNotificacao.SUCESSO
                                });
                                this.hasHistory() ? this.$router.go(-1) : this.$router.push("/");
                            }
                        }) :
                    this.store.commit(NOTIFICA, {
                        titulo: 'Configurações',
                        texto: 'Verifique os campos obrigatórios',
                        tipo: TipoNotificacao.ATENCAO,
                    });
            });
        },
        testarEmail(){
            (this.$refs.form as VForm).validate().then((obj) => {
                obj.valid ?            
                    this.store.dispatch(TESTAR_EMAIL, 
                    {'emailUserName':this.configuracao.emailUserName, 
                     'emailHost':this.configuracao.emailHost,
                     'emailPorta':this.configuracao.emailPorta,
                     'emailPassword':this.configuracao.emailPassword,
                     'emailSmtpAuth': this.configuracao.emailSmtpAuth,
                     'emailTLSEnabled': this.configuracao.emailTLSEnabled,
                     'emailTLSRequired': this.configuracao.emailTLSRequired,
                     'emailTest' :this.emailTest}
                    )
                        .then((response) => {                            
                            if (response.status == 200 ) {
                                this.store.commit(NOTIFICA, {
                                    titulo: 'Cadastro',
                                    texto: 'Verifique o email de destino',
                                    tipo: TipoNotificacao.SUCESSO
                                });
                            }
                        }) :
                    this.store.commit(NOTIFICA, {
                        titulo: 'Configurações',
                        texto: 'Verifique os campos obrigatórios',
                        tipo: TipoNotificacao.ATENCAO,
                    });
            });
        }
    },
    setup() {
        const store = useStore();

        const valid = ref(false);

        return {
            store,
            valid,
        }

    }
})
