import { Directive } from "vue";

export const mask: Directive<any, string> = {
    updated(el: any, binding: any) {

        const inputEl = el instanceof HTMLInputElement
            ? el
            : el.querySelector('input') || el

        if (!inputEl)
            return

        inputEl.value = mascara(binding.value, inputEl.value);
    },
}

export const mascara = function (tipo: string, value: string): string {
    if (!value) {
        return "";
    }
    value = value.replace(/\D/g, '').trim();
    switch (tipo) {
        case 'CEP':
            return value.replace(/(\d{5})(\d{3})/, '$1-$2');
        case 'CPFCNPJ':
            return value.length === 11 ? value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') :
                value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        case 'TEL':
            return value.length === 11 ? value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3') :
                value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        default:
            return value;
    }

}