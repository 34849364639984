import { Module } from "vuex";
import { Estado } from "@/store";
import { OBTER_MEDICAMENTO, OBTER_REVISTA } from "./tipo-acoes-medicamento";
import { http, trataErro } from "@/service/http";
import { DEFINE_MEDICAMENTO, DEFINE_REVISTA } from "./tipo-mutacoes-medicamento";
import { IDetalheRevista } from "@/interfaces/IRevistaMedicamento";
import { IDetalheMedicamento } from "@/interfaces/IDetalheMedicamento";
import { CARREGA } from "../tipo-mutacoes";


export interface MedicamentoEstado {
    medicamentos: IDetalheRevista,
    medicamento: IDetalheMedicamento
}

export const MedicamentoModulo: Module<MedicamentoEstado, Estado> = {
    state: {
        medicamentos: {} as IDetalheRevista,
        medicamento: {} as IDetalheMedicamento
    },
    mutations: {
        [DEFINE_REVISTA](state, medicamentos: IDetalheRevista) {
            state.medicamentos = medicamentos;
        },
        [DEFINE_MEDICAMENTO](state, medicamento: IDetalheMedicamento) {
            state.medicamento = medicamento;
        }
    },
    actions: {
        [OBTER_REVISTA](context, { page, size, laboratorio, principioAtivo, produto,codBarra }) {
            context.commit(CARREGA, true);
            return http.get('/medicamento/listar', {
                params: {
                    page: page,
                    size: size,
                    sort: 'ordem',
                    laboratorio: laboratorio,
                    principioAtivo: principioAtivo,
                    produto: produto,
                    codBarra: codBarra                    
                }
            }).then((response) => {
                context.commit(DEFINE_REVISTA, response.data as IDetalheRevista);
            }).catch((erro) => trataErro(context, 'Medicamento', erro))
                .finally(() => context.commit(CARREGA, false));
        },
        [OBTER_MEDICAMENTO](context, idMedicamento: number) {
            context.commit(CARREGA, true);
            return http.get('/medicamento', {
                params: {
                    id: idMedicamento
                }
            }).then((response) => {

                context.commit(DEFINE_MEDICAMENTO, response.data as IDetalheMedicamento);
                return response.data
            }).catch((erro) => trataErro(context, 'Medicamento', erro)).finally(() => context.commit(CARREGA, false));
        }
    },
    getters: {
        medicamentos(state) {
            return state.medicamentos
        }
    }
}