import { Module } from "vuex";
import { Estado } from "@/store";
import { DEFINE_MODULOS } from "./tipo-mutacoes-usuario";
import { ADICIONAR_MODULO, OBTER_MODULO, OBTER_MODULOS } from "./tipo-acoes-modulo";
import { http, trataErro } from "@/service/http";
import { IDetalheModulo } from "@/interfaces/IDetalheModulo";
import { IPage } from "@/interfaces/IPage";


export interface EstadoModulo {
    modulos: IPage<IDetalheModulo>
}

export const modulo: Module<EstadoModulo, Estado> = {
    mutations: {
        [DEFINE_MODULOS](state, modulos: IPage<IDetalheModulo>) {
            state.modulos = modulos
        }
    },
    actions: {
        [OBTER_MODULOS](context, { page, size }) {
            return http.get('/modulo/listar', {
                params: {
                    page: page,
                    size: size,
                    sort: 'descricao'
                }
            }).then((response) => {
                context.commit(DEFINE_MODULOS, response.data);
            }).catch((erro) => trataErro(context, 'Módulo', erro));
        },
        [OBTER_MODULO](context, id: number) {
            return http.get('/modulo', {
                params: {
                    id: id
                }
            }).catch((erro) => trataErro(context, 'Módulo', erro));
        },
        [ADICIONAR_MODULO](context, { form, modificando
        }) {
            return (modificando) ?
                http.put('/modulo', JSON.stringify(form),
                    {
                        params: {
                            id: form.id
                        }
                    }

                ).catch(error => {
                    trataErro(context, 'Módulo', error);
                })
                :
                http.post('/modulo', JSON.stringify(form))
                    .catch(error => {
                        trataErro(context, 'Módulo', error);
                    })
        },
    },
    getters: {
        modulos(state) {
            return state.modulos
        }
    }
}