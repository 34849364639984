import { IDetalheUsuario } from "@/interfaces/IDetalheUsuario";
import decode from "jwt-decode";


interface Token {
    iss?: string;
    sub?: string;
    aud?: string[] | string;
    exp?: number;
    nbf?: number;
    iat?: number;
    jti?: string;
}


export function getUser(token: string | undefined): IDetalheUsuario | undefined {
    if (!token)
        return undefined;
    const tokenJWT = decode<Token>(token);
    if (tokenJWT.sub) {
        const objReturn = JSON.parse(tokenJWT.sub);
        return objReturn as IDetalheUsuario;
    }
    return undefined;
}

export function isAuthenticated(token: string | undefined) {
    if (!token)
        return false;

    try {
        const tokenJWT = decode<Token>(token);
        if (!tokenJWT)
            return false;
        const isExpired = !!tokenJWT.exp && Date.now() > tokenJWT.exp * 1000;

        if (isExpired)
            return false;
        return true;
    } catch (_) {
        return false;
    }
}