
import { TipoNotificacao } from '@/interfaces/INotificacao';
import { useStore } from '@/store';
import { MUDAR_DATA_BLOQUEIO, MUDAR_MODULO, RECUPERAR_DATA_BLOQUEIO, RECUPERAR_MODULO } from '@/store/tipo-acoes';
import { NOTIFICA } from '@/store/tipo-mutacoes';
import { defineComponent, ref } from 'vue';
import { VForm } from 'vuetify/components/VForm';
import validator from '@/util/validator';
import { computed } from 'vue';
import { IDetalheModulo } from "@/interfaces/IDetalheModulo";
import { onMounted } from 'vue';
import { OBTER_MODULOS } from '@/store/modulo/tipo-acoes-modulo';

export default defineComponent({
    name: 'integracao-locaweb',
    data() {
        return {
            bloqueio: {
                nr_serie: Number,
                data: Date
            },
            modulo: {
                nr_serie: Number,
                modulo: {} as IDetalheModulo
            },
            requerid: validator.methods.required

        }
    },
    methods: {
        mudarData() {
            (this.$refs.form as VForm).validate().then((obj) => {
                obj.valid ?
                    this.store.dispatch(MUDAR_DATA_BLOQUEIO, {
                        nrSerie: this.bloqueio.nr_serie, dataBloqueio:
                            this.bloqueio.data
                    }).
                        then((response) => {
                            if (response.status == 200 || response.status == 201) {
                                this.store.commit(NOTIFICA, {
                                    titulo: 'LocaWeb',
                                    texto: 'Data Alterada',
                                    tipo: TipoNotificacao.SUCESSO
                                });

                            }
                        }) :
                    this.store.commit(NOTIFICA, {
                        titulo: 'Bloqueio',
                        texto: 'Verifique os campos obrigatórios',
                        tipo: TipoNotificacao.ATENCAO,
                    });
            });

        },
        mudarModulo() {
            (this.$refs.form as VForm).validate().then((obj) => {
                obj.valid ?
                    this.store.dispatch(MUDAR_MODULO, {
                        nrSerie: this.modulo.nr_serie, modulo_id:
                            this.modulo.modulo.id
                    }).
                        then((response) => {
                            if (response.status == 200 || response.status == 201) {
                                this.store.commit(NOTIFICA, {
                                    titulo: 'LocaWeb',
                                    texto: 'Modulo Alterado',
                                    tipo: TipoNotificacao.SUCESSO
                                });

                            }
                        }) :
                    this.store.commit(NOTIFICA, {
                        titulo: 'Bloqueio',
                        texto: 'Verifique os campos obrigatórios',
                        tipo: TipoNotificacao.ATENCAO,
                    });
            });
        },
        recuperarData() {
            if (Number(this.bloqueio.nr_serie) > 0) {
                this.store.dispatch(RECUPERAR_DATA_BLOQUEIO, this.bloqueio.nr_serie).
                    then((response) => {
                        if (response.status == 200 || response.status == 201) {
                            this.store.commit(NOTIFICA, {
                                titulo: 'LocaWeb',
                                texto: 'Consulta data',
                                tipo: TipoNotificacao.SUCESSO
                            });
                            if (response.data != null)
                                this.bloqueio.data = response.data.dataBloqueio;

                        }
                    });
            }
        },

        recuperarModulo() {
            if (Number(this.modulo.nr_serie) > 0) {
                this.store.dispatch(RECUPERAR_MODULO, this.modulo.nr_serie).
                    then((response) => {
                        if (response.status == 200 || response.status == 201) {
                            this.store.commit(NOTIFICA, {
                                titulo: 'LocaWeb',
                                texto: 'Consulta Modulo',
                                tipo: TipoNotificacao.SUCESSO
                            });
                            if (response.data != null)
                                this.modulo.modulo = response.data;

                        }
                    });
            }
        }
    },
    setup() {
        const store = useStore();
        const valid = ref(false);

        onMounted(() => store.dispatch(OBTER_MODULOS, { page: 0, size: 100 }));

        return {
            store,
            valid,
            modulos: computed(() => { return (store.state.modulo.modulos) ? store.state.modulo.modulos.content : undefined })
        }
    }
})
