import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createBlock(_component_v_container, {
    "fill-height": "",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "bg-surface mx-auto px-6 py-6" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "bg-primary" }, {
            default: _withCtx(() => [
              _createTextVNode(" Mudar Data Bloqueio ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { dense: true }, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                ref: "form",
                "validate-on": "blur",
                onSubmit: _withModifiers(_ctx.mudarData, ["prevent"]),
                class: "w-100"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { dense: true }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                density: "compact",
                                type: "number",
                                autofocus: "",
                                modelValue: _ctx.bloqueio.nr_serie,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bloqueio.nr_serie) = $event)),
                                rules: [_ctx.requerid],
                                label: "Código",
                                required: ""
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "12",
                            md: "2"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                color: "primary",
                                type: "button",
                                variant: "outlined",
                                class: "mt-4 w-100",
                                onClick: _ctx.recuperarData
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Consultar ")
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                type: "date",
                                ref: "nome",
                                density: "compact",
                                modelValue: _ctx.bloqueio.data,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bloqueio.data) = $event)),
                                label: "Liberar",
                                clearable: ""
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "12",
                            md: "2"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                color: "primary",
                                type: "submit",
                                variant: "outlined",
                                class: "mt-4 w-100"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Confirmar ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSubmit"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card, { class: "bg-surface mx-auto px-6 py-6" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "bg-primary" }, {
            default: _withCtx(() => [
              _createTextVNode(" Mudar Môdulo ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                ref: "form",
                "validate-on": "blur",
                onSubmit: _withModifiers(_ctx.mudarModulo, ["prevent"]),
                class: "w-100"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { dense: true }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                density: "compact",
                                type: "number",
                                autofocus: "",
                                modelValue: _ctx.modulo.nr_serie,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modulo.nr_serie) = $event)),
                                rules: [_ctx.requerid],
                                label: "Código",
                                required: ""
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "12",
                            md: "2"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                color: "primary",
                                type: "button",
                                variant: "outlined",
                                class: "mt-4 w-100",
                                onClick: _ctx.recuperarModulo
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Consultar ")
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                density: "compact",
                                label: "Select",
                                items: _ctx.modulos,
                                "item-title": "descricao",
                                "item-value": "id",
                                modelValue: _ctx.modulo.modulo,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modulo.modulo) = $event)),
                                "return-object": "",
                                "single-line": ""
                              }, null, 8, ["items", "modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            sm: "12",
                            md: "2"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                color: "primary",
                                type: "submit",
                                variant: "outlined",
                                class: "mt-4 w-100"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Confirmar ")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSubmit"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}