
import { useStore } from '@/store';
import { OBTER_USUARIOS } from '@/store/usuario/tipo-acoes-usuario';
import { DEFINE_USUARIOS } from '@/store/usuario/tipo-mutacoes-usuario';
import { onMounted } from 'vue';
import { computed } from 'vue';
import { onUnmounted } from 'vue';
import { defineComponent } from 'vue';
import { mascara } from '@/directive/mask';
import { IDetalheUsuario } from '@/interfaces/IDetalheUsuario';

export default defineComponent({
    name: 'busca-usuario',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
    },
    emits: ['aoSelecionarUsuario'],
    data() {
        return {
            filtro: '',
            dialogValue: false
        }
    },
    methods: {
        filtrar() {
            this.store.dispatch(OBTER_USUARIOS, this.filtro);
        },
        mascTel(value: string): string {
            return mascara('TEL', value)
        },
        selecionarUsuario(usuarioSel: IDetalheUsuario) {
            this.$emit('aoSelecionarUsuario', usuarioSel);
        }
    },
    mounted() {
        this.dialogValue = this.visible;
    },
    setup() {
        const store = useStore();
        onUnmounted(() => store.commit(DEFINE_USUARIOS, []));
        onMounted(() => store.dispatch(OBTER_USUARIOS));
        const usuarios = computed(() => store.state.usuario.usuarios);


        return {
            store,
            usuarios
        }
    }
});

