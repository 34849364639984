import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c70d64a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notificacoes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificacoes, (notificacao) => {
      return (_openBlock(), _createElementBlock("div", {
        key: notificacao.id
      }, [
        _createVNode(_component_v_alert, {
          class: "text-left",
          title: notificacao.titulo,
          text: notificacao.texto,
          type: _ctx.contexto[notificacao.tipo],
          icon: '$'.concat(_ctx.contexto[notificacao.tipo]),
          absolute: ""
        }, null, 8, ["title", "text", "type", "icon"])
      ]))
    }), 128))
  ]))
}