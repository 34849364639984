export default {
    methods: {
        required(v: any) {
            return !!v || 'Campo Obrigatório'
        },
        cpfcnpj(value: any) {

            if (value == undefined) return true

            if (value.trim().length === 0) return true

            if (typeof value !== 'string') return false || 'CPF/CNPJ não é valido'
            value = value.replace(/[^\d]+/g, '')

            if (value.match(/(\d)\1{10}/)) return false || 'CPF/CNPJ não é valido'

            if (value.length !== 11)
                if (value.length !== 14) return false || 'CPF/CNPJ não é valido'

            value = value.split('').map((el: number) => +el)


            const rest = (count: number) => (value.slice(0, count - 12)
                .reduce((soma: number, el: number, index: number) => (soma + el * (count - index)), 0) * 10) % 11 % 10
            if (value.length == 11) {
                const retorno: boolean = rest(10) === value[9] && rest(11) === value[10];
                return retorno || 'CPF/CNPJ não é valido'
            } else {
                const calc = (x: number) => {
                    const slice = value.slice(0, x)
                    let factor = x - 7
                    let sum = 0

                    for (let i = x; i >= 1; i--) {
                        const n = slice[x - i]
                        sum += n * factor--
                        if (factor < 2) factor = 9
                    }

                    const result = 11 - (sum % 11)

                    return result > 9 ? 0 : result
                }
                const digits = value.slice(12)

                const digit0 = calc(12)
                if (digit0 !== digits[0]) return false || 'CPF/CNPJ não é valido'

                const digit1 = calc(13)
                return digit1 === digits[1] || 'CPF/CNPJ não é valido'
            }
        },
        email(value: any) {
            const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

            return expression.test(value) || 'Email não é valido'
        }
    }
}