
import { IDetalheAtualizacao } from '@/interfaces/IDetalheAtualizacao';
import { TipoNotificacao } from '@/interfaces/INotificacao';
import { useStore } from '@/store';
import { INICAR_ATUALIZACAO, FILTRAR_ATUALIZACAO, EFETUAR_DOWNLOAD_RECEITA, EFETUAR_DOWNLOAD_EXCEL } from '@/store/atualizacao/tipo-acoes-atualizacao';
import { NOTIFICA } from '@/store/tipo-mutacoes';
import { computed } from '@vue/reactivity';
import { defineComponent } from 'vue';
import { VForm } from 'vuetify/components/VForm';


const dateAtual = new Date();

export default defineComponent({
    name: 'atualizacao-preco',
    data() {
        return {
            panel: [0],
            dataVigencia: new Date().toISOString().substring(0, 10),
            descricao: '',
            arquivo: undefined,
            atualizacao: {
                horario: new Date(),
                dataVigencia: new Date(),
            } as IDetalheAtualizacao,
            dataInicioFiltro: new Date(dateAtual.getFullYear(), dateAtual.getUTCMonth(), 1).toISOString().substring(0, 10),
            dataFinalFiltro: new Date().toISOString().substring(0, 10),
            isLoading: false,
        }
    },
    methods: {
        downloadReceita(id: number) {
            this.isLoading = true;
            this.store.dispatch(EFETUAR_DOWNLOAD_RECEITA, id).then(() => {
                this.isLoading = false;
            });
        },
        downloadExcel(id: number) {
            this.isLoading = true;
            this.store.dispatch(EFETUAR_DOWNLOAD_EXCEL, id).then(() => {
                this.isLoading = false;
            });
        },
        atualizar() {
            (this.$refs.form as VForm).resetValidation();
            if (!(this.$refs.form as VForm).validate()) {
                this.store.commit(NOTIFICA, {
                    titulo: 'Módulo',
                    texto: 'Verifique os campos obrigatórios',
                    tipo: TipoNotificacao.ATENCAO,
                });
                return
            }
            if (this.arquivo) {
                this.store.dispatch(INICAR_ATUALIZACAO, {
                    dataVigencia: this.dataVigencia,
                    descricao: this.descricao,
                    file: this.arquivo[0],
                });
            }
        },
        filtrar() {
            this.store.dispatch(FILTRAR_ATUALIZACAO, {
                dataInicio: this.dataInicioFiltro,
                dataFinal: this.dataFinalFiltro
            })
        }

    },
    setup() {
        const store = useStore();

        store.dispatch(FILTRAR_ATUALIZACAO, {
            dataInicio: new Date().toISOString().substring(0, 10),
            dataFinal: new Date().toISOString().substring(0, 10)
        })


        return {
            store,
            listaAtualizacoes: computed(() => store.state.atualizacao.atualizacoes),
            detalheErro: computed(() => store.state.atualizacao.dadosErroAtualizacaoAtual),
            atualizacaoAtual: computed(() => store.state.atualizacao.dadosAtualizacaoAtual),
            usuarioLogado: computed(() => store.state.usuarioAuth)
        }
    }
})
