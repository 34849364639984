
import { ExtractPropTypes } from "vue";




function fixed(precision: number): number {
    return Math.max(0, Math.min(precision, 1000));
}

function numbersToCurrency(numbers: string, precision: number): string {
    numbers = numbers.padStart(precision + 1, '0');
    return precision === 0 ? numbers : `${numbers.slice(0, -precision)}.${numbers.slice(-precision)}`;
}

function onlyNumbers(input: number | string): string {
    input = input ? input.toString() : '';
    return input.replace(/\D+/g, '') || '0';
}

function addThousandSeparator(integer: string, separator: string): string {
    return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`);
}

function replaceAt(str: string, index: number, chr: string | number): string {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
}

function guessFloatPrecision(string: string): number {
    const total = string.length;
    const index = string.indexOf('.');
    return total - (index + 1);
}

function round(string: string, precision: number): string {
    const diff = precision - guessFloatPrecision(string);
    if (diff >= 0) {
        return string;
    }

    let firstPiece = string.slice(0, diff);
    const lastPiece = string.slice(diff);

    if (firstPiece.charAt(firstPiece.length - 1) === '.') {
        firstPiece = firstPiece.slice(0, -1);
    }


    if (parseInt(lastPiece.charAt(0), 10) >= 5) {
        for (let i = firstPiece.length - 1; i >= 0; i -= 1) {
            const char = firstPiece.charAt(i);
            if (char !== '.' && char !== '-') {
                const newValue = parseInt(char, 10) + 1;
                if (newValue < 10) {
                    return replaceAt(firstPiece, i, newValue);
                }

                firstPiece = replaceAt(firstPiece, i, '0');
            }
        }

        return `1${firstPiece}`;
    }
    return firstPiece;
}

function joinIntegerAndDecimal(integer: string, decimal: string, separator: string): string {
    return decimal ? integer + separator + decimal : integer;
}

function isValidFloat(str: string): boolean {
    return (/^-?[\d]+(\.[\d]+)$/g).test(str);
}


export const mascaraMoney = function (input: string | number | null | undefined,
    options: VMoneyOptions | ExtractPropTypes<never> = vMoneyOptionsDefault): string {

    const opt = vMoneyOptionsDefault;
    if (options.prefix)
        opt.prefix = options.prefix
    if (options.suffix)
        opt.suffix = options.suffix
    if (options.thousands)
        opt.thousands = options.thousands
    if (options.decimal)
        opt.decimal = options.decimal
    if (options.precision)
        opt.precision = options.precision
    if (options.allowBlank)
        opt.allowBlank = options.allowBlank

    if (input === null || input === undefined) {
        input = '';
    } else if (typeof input === 'number') {
        input = input.toFixed(fixed(opt.precision || 2) + 1).slice(0, -1);

        let filtered = input.replace(opt.prefix || '', '').replace(opt.suffix || '', '');

        if (!opt.precision && opt.thousands !== '.' && isValidFloat(filtered)) {
            filtered = round(filtered, 0);
        }
        const currency = numbersToCurrency(onlyNumbers(filtered), opt.precision || 2)

        if ((/^0(\.0+)?$/g).test(currency) && opt.allowBlank) {
            return '';
        }
        // eslint-disable-next-line prefer-const
        let [integer, decimal] = currency.split('.');
        integer = addThousandSeparator(integer, opt.thousands || '');

        const output = opt.prefix
            + joinIntegerAndDecimal(integer, decimal, opt.decimal || ',')
            + opt.suffix;


        return output;
    }
    return input;
}

export interface VMoneyOptions {
    prefix: string;
    suffix: string;
    thousands: string;
    decimal: string;
    precision: number;
    allowBlank: boolean
}

export const vMoneyOptionsDefault = {
    prefix: '',
    suffix: '',
    thousands: '.',
    decimal: ',',
    precision: 2,
    allowBlank: true
} as VMoneyOptions;


