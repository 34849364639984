
import { defineComponent } from "vue";
import Notificacoes from "./components/Notificacao.vue"
import { useStore } from "./store";
import { getUser } from './service/auth';
import { USUARIO_LOGADO } from "./store/usuario/tipo-mutacoes-usuario";
import LoaderComponent from '@/components/LoaderComponent.vue';
import { computed } from "vue";

export default defineComponent({
  name: 'App',
  components: {
    Notificacoes,
    LoaderComponent
  },
  setup() {
    const store = useStore();


    return {
      store,
      loading : computed(() => { return (store.state.modulo.modulos) ? store.state.modulo.modulos.content : undefined })
    }

  },
  mounted() {
    if (this.store.state.usuarioAuth === undefined) {
      this.store.commit(USUARIO_LOGADO, getUser(this.store.state.token));
    }
  }
});
