import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57b8858f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "h-screen w100 progress",
  "bg-color": "surface"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_progress_circular, {
          class: "center",
          width: 5,
          size: 100,
          color: "primary",
          indeterminate: ""
        })
      ]))
    : _createCommentVNode("", true)
}