import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Configuracao from "../views/Configuracao.vue";
import { isAuthenticated } from '../service/auth'
import TrocarSenha from "@/views/TrocarSenha.vue";
import ListaUsuario from "@/views/usuario/ListaUsuario.vue"
import CadastroUsuario from "@/views/usuario/CadastroUsuario.vue";
import ListaModulo from "@/views/modulo/ListaModulo.vue"
import CadastroModulo from "@/views/modulo/CadastroModulo.vue";
import RevistaEletronica from "@/views/medicamento/RevistaEletronica.vue";
import AtualizacaoPreco from "@/views/atualizacao/AtualizacaoPreco.vue";
import ParametroUsuario from "@/views/usuario/ParametroUsuario.vue";
import IntegracaoLocaWeb from "@/views/IntegracaoLocaWeb.vue";
import CobrancaUsuario from "@/views/cobranca/CobrancaUsuario.vue";

import { store } from "@/store";

const rotas: RouteRecordRaw[] = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
            { path: '/trocar-senha', name: 'trocar-senha', component: TrocarSenha },

            { path: '/usuario/lista', name: 'lista-usuario', component: ListaUsuario },
            { path: '/usuario/novo', name: 'novo-usuario', component: CadastroUsuario },
            { path: '/usuario/cadastro/:id', name: 'cadastro-usuario', component: CadastroUsuario, props: true },
            { path: '/usuario/parametro/:id', name: 'parametro-usuario', component: ParametroUsuario, props: true },

            { path: '/cobrancas', name: 'cobranca-usuario', component:  CobrancaUsuario},


            { path: '/modulo/lista', name: 'lista-modulo', component: ListaModulo },
            { path: '/modulo/novo', name: 'novo-modulo', component: CadastroModulo },
            { path: '/modulo/cadastro/:id', name: 'cadastro-modulo', component: CadastroModulo, props: true },

            { path: '/configuracao', name: 'configuracao', component: Configuracao },
            { path: '/integracao-locaweb', name: 'Integracao-locaweb', component: IntegracaoLocaWeb },

            { path: '/atualizacao', name: 'atualizacao-preco', component: AtualizacaoPreco }
        ]
    },
    {
        path: '/revista',
        name: 'revista-eletronica',
        component: RevistaEletronica
    },
]

const roteador = createRouter({
    history: createWebHashHistory(),
    routes: rotas
})


roteador.beforeEach(async (to) => {
    if (!isAuthenticated(store.state.token) && to.name !== 'Login') {
        return { name: 'Login' }
    }
})


export default roteador;
