

import validator from '@/util/validator';
import { defineComponent, ref } from 'vue';
import { useStore } from '@/store';
import { IDetalheUsuario } from '@/interfaces/IDetalheUsuario';
import { ADICIONAR_COBRANCA, OBTER_COBRANCAS, OBTER_USUARIOS, OBTER_USUARIO_IDSISTEMA, REMOVER_COBRANCA, DOWNLOAD_COBRANCA, MUDAR_ESTADO_COBRANCA } from '@/store/usuario/tipo-acoes-usuario';
import { CARREGA, NOTIFICA } from '@/store/tipo-mutacoes';
import { computed } from 'vue';
import { VForm } from 'vuetify/components/VForm';
import { TipoNotificacao } from '@/interfaces/INotificacao';
import BuscarUsuario from '@/components/BuscarUsuario.vue';
import { IDetalheCobranca } from '@/interfaces/IDetalheCobranca';

export default defineComponent({
    name: 'cobranca-usuario',
    components: {
        BuscarUsuario
    },
    data() {
        return {
            dialog: false,
            dialogLiquidacao: false,
            mostrarCobrancas: false,
            usuarioSelecionado: {} as IDetalheUsuario,
            dataVencimento: new Date(),
            arquivo: undefined,

            dataVencimentoLiquidacao: new Date(),
            dataLiquidacao: new Date(),

            requerid: validator.methods.required
        }
    },
    methods: {
        hasHistory() { return window.history.length > 2 },
        usuarioFilterSerie(filter: any) {
            this.store.dispatch(OBTER_USUARIO_IDSISTEMA, filter).then(response => {
                this.usuarioSelecionado = response.data;
                this.store.commit(CARREGA, false);
                if (this.usuarioSelecionado) {
                    this.store.dispatch(OBTER_USUARIOS, this.usuarioSelecionado.nome);
                } else
                    (this.$refs.idUsuario as any).focus();
            });
        },
        filtroUsuario(queryText: string, query: string, item: any) {
            if (queryText != null && item != null && item.description) {
                return this.store.dispatch(OBTER_USUARIOS, query);
            }
            return -1

        },
        onConsulta() {
            (this.$refs.form as VForm).validate().then((obj) => {
                (obj.valid) ?
                    this.store.dispatch(OBTER_COBRANCAS, this.usuarioSelecionado.id).then(() => { this.mostrarCobrancas = true }).catch(() => { this.mostrarCobrancas = false }) :
                    this.store.commit(NOTIFICA, {
                        titulo: 'Cobrança',
                        texto: 'Verifique os campos obrigatórios',
                        tipo: TipoNotificacao.ATENCAO,
                    });

            });
        },
        adicionaBoleto() {
            console.log("abriu");
            if (this.arquivo) {
                this.store.dispatch(ADICIONAR_COBRANCA, {
                    idUsuario: this.usuarioSelecionado.id,
                    dataVencimento: this.dataVencimento,
                    file: this.arquivo[0]
                }).then(() => {
                    this.dataVencimento = new Date();
                    this.arquivo = undefined;
                    this.store.dispatch(OBTER_COBRANCAS, this.usuarioSelecionado.id).then(() => { this.mostrarCobrancas = true }).catch(() => { this.mostrarCobrancas = false });
                })
            }

        },
        removerCobranca(dataVencimentoSel: Date) {
            this.store.dispatch(REMOVER_COBRANCA, {
                id: this.usuarioSelecionado.id, vencimento: new Date(dataVencimentoSel).toISOString().substring(0, 10)
            }).then(() => {
                this.dataVencimento = new Date();
                this.arquivo = undefined;
                this.store.dispatch(OBTER_COBRANCAS, this.usuarioSelecionado.id).then(() => { this.mostrarCobrancas = true }).catch(() => { this.mostrarCobrancas = false });
            });
        },
        abrirBusca() {
            this.dialog = true;
        },
        selecionarUsuario(u: IDetalheUsuario) {
            this.dialog = false;
            this.usuarioSelecionado = u;
            console.log(u.nome);
            this.store.dispatch(OBTER_USUARIOS, this.usuarioSelecionado.nome);
        },
        download(dataVencimentoSel: Date) {
            this.store.dispatch(DOWNLOAD_COBRANCA, {
                id: this.usuarioSelecionado.id, vencimento: new Date(dataVencimentoSel).toISOString().substring(0, 10)
            }).then((resp) => {
                const fileWindow = window.open();
                const response = resp.data;
                fileWindow!.document.write(
                    '<title>Cobrança</title>' +
                    '<body style="overflow: hidden; margin: 0">' +
                    '<object width="100%" width="-webkit-fill-available" height="100%" height="-webkit-fill-available" type="application/pdf" data="' + encodeURI(response) + '"></object>' +
                    '</body>'
                );

            });
        },
        liquidar(dataVencimentoSel: Date) {
            this.dialogLiquidacao = true;
            this.dataVencimentoLiquidacao = dataVencimentoSel;
        },
        efetuarLiquidacao() {
            (this.$refs.formLiquidar as VForm).validate().then((obj) => {
                (obj.valid) ?
                    this.store.dispatch(MUDAR_ESTADO_COBRANCA, {
                        id: this.usuarioSelecionado.id,
                        vencimento: new Date(this.dataVencimentoLiquidacao).toISOString().substring(0, 10),
                        dataLiquidacao: new Date(this.dataLiquidacao).toISOString().substring(0, 10),
                    }).then(() => { this.dialogLiquidacao = false }) :
                    this.store.commit(NOTIFICA, {
                        titulo: 'Cobrança',
                        texto: 'Verifique os campos obrigatórios',
                        tipo: TipoNotificacao.ATENCAO,
                    });

            });

        }
    },
    setup() {
        const store = useStore();

        const valid = ref(false);

        const validCob = ref(false);

        const validDialogLiquidacao = ref(false);

        function createDebounce() {
            let timeout: any = null;
            return function (fnc: any, delayMs: any) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        store.dispatch(OBTER_USUARIOS, "");


        const cobrancas = computed(() => (store.state.usuario.cobrancas) ? store.state.usuario.cobrancas : {} as IDetalheCobranca[]);

        return {
            store,
            valid,
            validDialogLiquidacao,
            validCob,
            cobrancas,

            debounce: createDebounce()
        }

    }

})
