export enum TipoNotificacao {
    SUCESSO,
    FALHA,
    ATENCAO,
    INFORMACAO
}

export interface INotificacao {
    titulo: string
    texto: string
    detalhe : string
    tipo: TipoNotificacao
    id: number
}