import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notificacoes = _resolveComponent("Notificacoes")!
  const _component_loaderComponent = _resolveComponent("loaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Notificacoes),
    _createVNode(_component_loaderComponent, { isLoading: _ctx.loading }, null, 8, ["isLoading"]),
    _createVNode(_component_v_app, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ], 64))
}