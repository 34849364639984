
import { IDetalheModulo } from '@/interfaces/IDetalheModulo';
import { TipoNotificacao } from '@/interfaces/INotificacao';
import { useStore } from '@/store';
import { ADICIONAR_MODULO, OBTER_MODULO } from '@/store/modulo/tipo-acoes-modulo';
import { CARREGA, NOTIFICA } from '@/store/tipo-mutacoes';
import validator from '@/util/validator';
import { defineComponent, ref } from 'vue';
import { VForm } from 'vuetify/components/VForm';

export default defineComponent({
    name: 'cadastro-modulo',
    props: ['id'],
    data() {
        return {
            modulo: {} as IDetalheModulo,
            modificar: false,
            requerid: validator.methods.required
        }
    },
    mounted() {
        if (this.id || this.id > 0) {
            this.store.commit(CARREGA, true);
            this.modificar = true;
            this.store.dispatch(OBTER_MODULO, this.id).then(response => {
                const moduloGet = response.data;
                if (moduloGet) {
                    this.modulo = moduloGet;
                } else
                    (this.$refs.nome as any).focus();
            }).finally(() => this.store.commit(CARREGA, false))

        }
    },
    methods: {
        hasHistory() { return window.history.length > 2 },
        onSubmit() {
            (this.$refs.form as VForm).validate().then((obj) => {
                obj.valid ?
                    this.store.dispatch(ADICIONAR_MODULO, { form: this.modulo, modificando: this.modificar })
                        .then((response) => {
                            if (response.status == 200 || response.status == 201) {
                                this.store.commit(NOTIFICA, {
                                    titulo: 'Módulo',
                                    texto: response.status == 200 ? 'Módulo Alterado' : 'Módulo Cadastrado',
                                    tipo: TipoNotificacao.SUCESSO
                                });
                                this.hasHistory() ? this.$router.go(-1) : this.$router.push("/modulo/lista");
                            }
                        }) :
                    this.store.commit(NOTIFICA, {
                        titulo: 'Módulo',
                        texto: 'Verifique os campos obrigatórios',
                        tipo: TipoNotificacao.ATENCAO,
                    });
            });
        },
        naoPodeAlterar(): boolean {
            if (!this.id || this.id === undefined)
                return false;
            if (this.id || this.id > 0)
                return true;

            return false;
        }
    },
    setup() {
        const store = useStore();

        const valid = ref(false);

        return {
            store,
            valid,
        }

    }
})
