import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_barra_lateral = _resolveComponent("barra-lateral")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    "fill-height": "",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_barra_lateral),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}