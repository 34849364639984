import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import roteador from './roteador'
import { loadFonts } from './plugins/webfontloader'
import { key, store } from './store'
import { mask } from './directive/mask'

loadFonts()

import "@/assets/global.css"

createApp(App)
  .use(roteador)
  .use(store, key)
  .use(vuetify)
  .directive('mask', mask)
  .mount('#app')
