import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_container, {
    "fill-height": "",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        class: "bg-surface px-2 py-2 h-100",
        density: "compact"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "bg-primary" }, {
            default: _withCtx(() => [
              _createTextVNode(" Dados do Usuário ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_form, {
            ref: "form",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.valid) = $event)),
            "validate-on": "blur",
            onSubmit: _withModifiers(_ctx.onSubmit, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { dense: true }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "3",
                        md: "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            density: "compact",
                            type: "number",
                            autofocus: "",
                            modelValue: _ctx.modulo.id,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modulo.id) = $event)),
                            rules: [_ctx.requerid],
                            label: "Código",
                            required: "",
                            readonly: _ctx.naoPodeAlterar()
                          }, null, 8, ["modelValue", "rules", "readonly"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "12",
                        md: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            ref: "nome",
                            density: "compact",
                            modelValue: _ctx.modulo.descricao,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modulo.descricao) = $event)),
                            maxlength: "100",
                            counter: 100,
                            rules: [_ctx.requerid],
                            label: "Descrição",
                            required: ""
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6",
                        sm: "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            type: "number",
                            stpe: "0.01",
                            density: "compact",
                            modelValue: _ctx.modulo.valor,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modulo.valor) = $event)),
                            rules: [_ctx.requerid],
                            label: "Valor",
                            required: ""
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, {
                    justify: "space-between",
                    class: "text-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            size: "large",
                            type: "submit",
                            variant: "outlined",
                            class: "mt-4 w-100"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Confirmar ")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "warning",
                            size: "large",
                            type: "button",
                            variant: "outlined",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hasHistory() ? _ctx.$router.go(-1) : _ctx.$router.push('/'))),
                            class: "mt-4 w-100"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Cancelar ")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onSubmit"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}