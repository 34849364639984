

import { computed, defineComponent } from "vue"
import { useStore } from "@/store"
import { TipoNotificacao } from "@/interfaces/INotificacao";

export default defineComponent({
    name: "Notifica-Lista",
    data() {
        return {
            contexto: {
                [TipoNotificacao.SUCESSO]: "success",
                [TipoNotificacao.FALHA]: "error",
                [TipoNotificacao.ATENCAO]: "warning",
                [TipoNotificacao.INFORMACAO]: "info",
                "": undefined
            }
        }
    },
    setup() {
        const store = useStore();
        return {
            notificacoes: computed(() => store.state.notificacoes),
        };
    }

})

