
import { useStore } from '@/store';
import { defineComponent, computed, ref } from 'vue';
import { OBTER_MEDICAMENTO } from '@/store/medicamento/tipo-acoes-medicamento';
import { mascaraMoney } from '@/directive/money';

export default defineComponent({
    name: 'Detalhe-Medicamento-Revista',
    props: {
        id: {
            type: String,
            required: true
        },
        precoAtual: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            corTarja: '',
            precoVigente: this.precoAtual
        }
    },
    methods: {
        tarjaToText(tarja: string) {
            this.corTarja = 'bg-green';
            switch (tarja) {
                case 'VL': {
                    return 'Venda Liberada';
                }
                case 'TV': {
                    this.corTarja = 'bg-red';
                    return 'Tarja Vermelha'
                }
                case 'TP': {
                    this.corTarja = 'bg-black';
                    return 'Tarja Preta'
                }
                default:
                    return 'Venda Liberada'
            }
        },
        preco(precos: []) {
            this.precoVigente = precos;
        },
        datas() {
            return this.medicamento.precos ? new Map(Object.entries(this.medicamento.precos)).keys() :
                undefined;
        },
        mascaraMoney(value: string) {
            return mascaraMoney(value);
        },
        preparaTexto(dateString: string) {
            let d = dateString.split("-");
            let dat = new Date(d[1] + '/' + d[2] + '/' + d[0]);
            return dat.toLocaleDateString();
        }
    },
    setup(props, { emit }) {
        const store = useStore();
        let mostrar = ref(false);
        const fecharModal = () => {
            emit("update:id", 0);
        };

        let precoSelecionado = ref(0);

        store.dispatch(OBTER_MEDICAMENTO, props.id).then((ret) => {
            precoSelecionado.value = Object.keys(ret.precos).length - 1;
        });

        const medicamento = computed(() => store.state.medicamento.medicamento);

        return {
            store,
            mostrar,
            precoSelecionado,
            medicamento,
            fecharModal
        }
    }
});
