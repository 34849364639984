
import { useStore } from '@/store';
import { defineComponent, ref, onMounted, computed } from 'vue';
import { IDadosUsuario } from '@/interfaces/IDadosUsuario';
import validator from '@/util/validator';
import { ADICIONAR_USUARIO, OBTER_USUARIO, VERIFICAR_LOGIN, VERIFICAR_IDSISTEMA, VERIFICAR_CNPJ } from "@/store/usuario/tipo-acoes-usuario";
import { OBTER_MODULOS } from "@/store/modulo/tipo-acoes-modulo";
import { consultarCep } from "@/service/cep";
import { CARREGA, NOTIFICA } from '@/store/tipo-mutacoes';
import { TipoNotificacao } from '@/interfaces/INotificacao';
import { VForm } from 'vuetify/components/VForm';


export default defineComponent({
    name: 'cadastro-usuario',
    props: ['id'],
    data() {
        return {
            tab: 1,
            podeAlterar: true,
            usuario: {                
                administrador: false,
                funcionario: false,
                utilizarSistema: false,
                baixarXLSResvista: false,
                baixarXLSFabricante: false,
                baixarResvistaPDF: false,
            } as IDadosUsuario,
            requerid: validator.methods.required,
            email: validator.methods.email,
            cpfcnpj: validator.methods.cpfcnpj,
            cep: ''
        }
    },
    methods: {
        hasHistory() { return window.history.length > 2 },
        onSubmit() {
            (this.$refs.form as VForm).validate().then((obj) => {
                obj.valid ?
                    this.store.dispatch(ADICIONAR_USUARIO, this.usuario).
                        then((response) => {
                            if (response.status == 200 || response.status == 201) {
                                this.store.commit(NOTIFICA, {
                                    titulo: 'Usuários',
                                    texto: response.status == 200 ? 'Usuário Alterado' : 'Usuário Cadastrado',
                                    tipo: TipoNotificacao.SUCESSO
                                });
                                this.hasHistory() ? this.$router.go(-1) : this.$router.push("/usuario/lista");
                            }
                        }) :
                    this.store.commit(NOTIFICA, {
                        titulo: 'Usuário',
                        texto: 'Verifique os campos obrigatórios',
                        tipo: TipoNotificacao.ATENCAO,
                    });
            });
        },
        buscarCEP(event: any) {
            const cep: string = event.target.value.replace("-", "");
            if (cep.length === 8) {
                consultarCep(cep).then((cep) => {
                    this.usuario.endereco = cep.data.logradouro;
                    this.usuario.bairro = cep.data.bairro;
                    this.usuario.complemento = cep.data.complemento;
                    this.usuario.cidade = cep.data.localidade;
                    this.usuario.uf = cep.data.uf;
                });
            }
        },
        verificaLogin() {
            return this.store.dispatch(VERIFICAR_LOGIN, { id: this.usuario.id || 0, login: this.usuario.login }
            );
        },
        verificaIdSistema() {
            if (this.usuario.idSistema)
                return this.store.dispatch(VERIFICAR_IDSISTEMA, { id: this.usuario.id || 0, idSistema: this.usuario.idSistema });
            return true

        },
        verificaCNPJ() {
            if (this.usuario.cnpj) {
                return this.store.dispatch(VERIFICAR_CNPJ, { id: this.usuario.id || 0, cnpj: this.usuario.cnpj });
            }
            return true;
        }
    },
    mounted() {
        if (this.id || this.id > 0) {
            this.store.commit(CARREGA, true);
            this.store.dispatch(OBTER_USUARIO, this.id).then(response => {
                const usuarioGet = response.data;
                this.store.commit(CARREGA, false);
                if (usuarioGet) {
                    this.usuario = usuarioGet;
                } else
                    (this.$refs.nome as any).focus();
            });
        } else 
         this.usuario.ativo=true;
    },
    setup() {
        const store = useStore();

        const valid = ref(false);

        onMounted(() => store.dispatch(OBTER_MODULOS, { page: 0, size: 100 }));

        return {
            store,
            valid,
            modulos: computed(() => { return (store.state.modulo.modulos) ? store.state.modulo.modulos.content : undefined })
        }
    }
})
